body {
  width: 100%;
  height: 100%;
}

.galleryGrid {
  display: none;
}

.Name {
  position: relative;
  width: 900px;
  height: 350px;
  cursor: pointer;
  animation: blink 8s normal forwards;
  border-radius: 0.5cm;
  font-family: "Vibur", sans-serif;
  font-size: 3rem;
  letter-spacing: 10px;
  color: #1f0e28;
  padding: 20px;
  background-size: fit-content;
  margin-top: 1%;
}

.testimonials {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.testimonial {
  width: 50%;
  padding-bottom: 5%;
}

#testimonial1 {
  align-self: flex-start;
}

#testimonial2 {
  align-self: flex-end;
}

.clicked {
  animation: scroll 70s linear infinite;
  background: url(https://cdn.wallpapersafari.com/55/20/PD5uRm.jpg), #111111;
  background-size: 130%;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  margin-right: 1rem;
  margin-top: 4rem !important;
}

.continueBtn {
  cursor: pointer;
}

.codeBG {
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -15%;
}

.AboutSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}

.slideUp {
  animation-name: slideUp;
  animation-name: slideUp;
  animation-duration: 1s;
  animation-duration: 1s;
  visibility: visible;
}

.galleryContainer {
  display: flex;
  width: 90vw;
}

.card {
  position: relative;
  margin: 6px;
  height: 80vh;
  flex: 0.5;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  transition: flex 0.3s ease-in;
  border-color: black;
  border-style: solid;
}

.projDescrip {
  max-width: 500px;
}

.sectionDiv {
  width: 80%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
  border-top: 1px solid black;
}

.landingBG {
  width: 100%;
  height: 100%;
  position: fixed;
}

.hackathonLink {
  background-color: rgb(49, 49, 49, 0.7);
  padding: 3px;
}

.recaptcha {
  padding-top: 10%;
}

.card h3 {
  position: absolute;
  bottom: 20px;
  left: 20px;

  font-size: 24px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.message {
  width: 200px;
  height: 50px;
}

.card.active {
  flex: 5;
}

.card.active h3 {
  opacity: 1;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  height: 100px;
  width: auto;
  overflow-y: hidden;
  margin-top: 5%;
  border-style: solid;
  border-color: black;
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0% !important;
  background-size: 100% 170%;
  background-position: center top;
  background-attachment: fixed;
}

.AboutImg {
  width: 50%;
}

.shadow {
  width: 40px;
  height: 40px;
  font: size 60px;
  box-shadow: 0.5px 0.5px 0 0.5px black, inset 1px 1px white,
    inset -1px -1px #85898d;
  margin-left: 24cm;
}

.shadow:hover {
  background-color: red;
}

h1 {
  padding: 10px;
}

.typewriter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skill-info {
  font-size: 20px;
}

.ArthurPic {
  width: 160px;
  height: 150px;
  border-radius: 60%;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  margin-top: 10px;
  margin-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10PX;
  left:10px;
}

.typewriter h2 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 5.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes blink {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  92% {
    color: #120c29;
    text-shadow: none;
    box-shadow: none;
    /* border: 3px solid rgba(22, 182, 212, 0.3); */
  }

  18.1%,
  20.1%,
  30%,
  50%,
  60.1%,
  65%,
  80.1%,
  100% {
    color: rgba(113, 213, 255, 0.5);
    text-shadow: 0 0 10px #7971f5, 0 0 20px #7971f5, 0 0 40px #7971f5,
      0 0 80px #7971f5, 0 0 160px #7971f5, 0 0 320px #7971f5, 0 0 640px #7971f5;
    /* box-shadow: inset 0 0 5px rgb(113 213 255 / 50%), inset 0 0 10px rgb(113 213 255 / 50%), 0 0 5px rgb(113 213 255 / 30%), 0 0 10px rgb(113 213 255 / 30%), 0 0 20px rgb(113 213 255 / 30%), 0 0 40px rgb(113 213 255 / 30%), 0 0 80px rgb(113 213 255 / 30%), 0 0 100px rgb(113 213 255 / 30%); */
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

/* The typewriter cursor effect */
/* @keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: blue;
  }
} */

.App {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
}

.nav {
  padding: 1%;
  height: 20%;
  border-radius: 6%;
  background-color: black;
  margin-top: 3%;
  border-radius: 5px;
  /* box-shadow:0px 10px 0px 0px #617072, 
    0px 0px 10px 0px rgba(120, 120, 120, 0.4); */
  cursor: pointer;
  font-size: 30px;
}

/* .nav:focus{
  outline:none;
}
.nav:active{
  box-shadow: 0px 5px 0px 0px #1d7f8a;
  top:5px;
} */

.contactForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid #000000;
  width: 50%;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.Name h1 {
  font-size: 1.5em;
}

.nav,
.nav:focus {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 1.5rem 2.5rem;
  text-decoration: none;
  /* background: black; */
  transition: all 0.4s ease-in-out;
}
.nav::before,
.nav::after {
  position: absolute;
  content: "";
  transition: all 0.4s ease-in-out;
}
.nav::before {
  bottom: -0.8rem;
  height: 0.8rem;
  width: 100%;
  right: 0.4rem;
  transform: skewX(-45deg);
  background-color: rgb(59, 59, 59);
}
.nav::after {
  left: -0.8rem;
  height: 100%;
  width: 0.8rem;
  bottom: -0.4rem;
  transform: skewY(-45deg);
  background: grey;
}

.clicked::before {
  bottom: -0.4rem;
  height: 0.5rem;
  right: 0.3rem;
}
.clicked::after {
  left: -0.4rem;
  width: 0.5rem;
  bottom: -0.3rem;
}

.navImgBG {
  background-color: black;
  top: 0%;
  bottom: 0%;
  height: 100%;
  width: fit-content;
}

.Navbar {
  border-bottom: black;
  border-style: solid;
  background: black;
  padding-right: 2%;
  background-repeat: no-repeat;
  background-size: 100% 170%;
  background-position: center bottom;
  background-attachment: fixed;
  margin-left: -2%;
}

.headComp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.heading {
  font-size: 60px;
  transform: rotate(3deg);
}

.skillsList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  width: 400px;
}

.applied-skill {
  width: 35px;
  height: 30px;
  padding: 10px;
}

.skill-item {
  display: flex;
  flex-direction: row;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;
}

.mark {
  transform: rotate(-2deg);
  background-image: url(https://img.freepik.com/free-photo/white-grunge-distressed-texture-background_1409-1786.jpg?w=2000);
  width: fit-content;
  height: 10%;
}

.cvBtn {
  color: white;
  background-color: black;
  padding: 10px;
  font-size: 30px;
  margin-top: 50px;
  text-decoration: none;
}

.Skills {
  padding-top: 5%;
}

.project-img {
  display: block;
  max-width: 500px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 7%;
  border-style: solid;
  border-color: black;
  box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
}

.grid-projects {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 30px;
  margin-left: 0%;
}

.grid-project {
  text-decoration: none !important;
  color: #000000;
}

.grid-item:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.skill-image {
  height: 60px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 70px;
  margin-left: 2%;
}

.grid-item {
  display: block;
  font-size: 30px;
  color: rgb(255, 255, 255);
  background-color: rgb(54, 52, 52);
  overflow: hidden;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 90%;
  height: 80%;
  border-radius: 5%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.passage {
  width: 75%;
  font-size: 22px;
}

#social {
  margin-left: 10px;
  width: 7%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.links {
  background-color: #000000;
  color: #fff;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}

.socialIcon {
  width: 50px;
  height: 50px;
  color: #000000;
}

.siteCreator {
  font-weight: bold;
  margin-right: 15px;
}

.arrowUp {
  width: 25px;
  height: 25px;
}

.landing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: url("https://cdn.pixabay.com/photo/2019/02/21/12/19/sunset-4011217_960_720.jpg"),
    #111111;
  background-repeat: no-repeat;
  background-size: 100%;
}

.testimonialHR {
  width: 60%;
}

.testimonialHighlight {
  background-color: #dfdfe4;
  line-height: 35px;
}

.qoutes {
  display: inline;
  font-size: 35px;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Header {
  animation: scroll 70s linear infinite;
  background: url("https://cdn.wallpapersafari.com/55/20/PD5uRm.jpg"), #111111;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  width: 60%;
  height: 70%;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 0.5cm;
  padding: 10px;
  margin: auto auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.page {
  text-align: left;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  color: white;
  font-size: 130%;
  width: 20%;
  height: 100%;
  font-stretch: expanded;
  position: absolute;
  opacity: 0.6;
}

.Name:hover {
  font-size: 3.2rem;
}

.App-link {
  color: #61dafb;
}

.navBtn {
  display: none;
}

.topPage {
  left: 2%;
  display: none;
  position: fixed;
  z-index: 4 !important;
  bottom: 20%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #ffa000;
  border-color: black;
  border-style: solid;
  box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px;
}

.Header::before {
  bottom: -0.8rem;
  height: 0.8rem;
  width: 100%;
  right: 0.4rem;
  transform: skewX(-45deg);
  background-color: rgb(59, 59, 59);
}
.Header::after {
  left: -0.8rem;
  height: 100%;
  width: 0.8rem;
  bottom: -0.4rem;
  transform: skewY(-45deg);
  background: grey;
}

h3 {
  font-family: "Press Start 2P", cursive;
  font-size: 66%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}

@media (prefers-reduced-motion) {
  .wrapper {
    animation: scroll 200s linear infinite;
  }
}

@media (max-width: 375px) {
  .active {
    width: 140%;
    left: -72px;
  }
}

@media (max-width: 768px) {
  .galleryGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .hackathonLink {
    background-color: rgb(0 0 0 / 70%);
    padding: 3px;
    margin-top: -10%;
    font-family: inherit;
    color: rgb(255, 255, 255);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
  }

  .gridItem {
    width: 50%;
  }

  .galleryContainer {
    display: none;
  }

  .title {
    font-size: 5rem;
  }

  .card.active {
    flex: 15;
  }

  .Name {
    width: 600px;
    font-size: 2.3rem;
    margin-top: 0%;
  }

  .Name:hover {
    font-size: 2.3rem;
  }

  .shadow {
    margin-left: 18cm;
  }
}

@media (max-width: 430px) {
  #social{
    width: 20%;
  }

  .socialIcon{
    width: 35px;
  }

  .hackathonLink {
    margin-top: -15%;
  }

  .contactDetails h2 {
    text-align: center;
    font-size: 2em;
  }

  .gridItem {
    width: 80%;
  }

  .cardBG {
    display: none;
    background-color: #000000;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: fixed;
    top: 0%;
    opacity: 0.8;
  }

  h3 {
    font-size: 120%;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .sectionDiv {
    margin-top: 15%;
  }

  .title {
    font-size: 5rem;
  }

  .Header {
    font-size: calc(5px + 2vmin);
    top: -10% !important;
  }

  .project-img {
    max-width: 50% !important;
    min-height: 60% !important;
    max-height: 60% !important;
  }

  .Footer {
    margin-top: 21%;
  }

  .passage {
    font-size: 15px;
  }

  .navBtn {
    display: block;
  }

  #close {
    display: none;
  }

  .navIcon {
    height: 2em;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: larger;
  }

  .Name {
    width: 220px !important;
    height: 229px !important;
    font-size: 1.5rem;
    margin-top: 20%;
    letter-spacing: 3px;
  }

  .contactForm {
    border-left: 2px solid #ffffff;
    border-top: 2px solid #000000;
  }

  .contactForm h2 {
    text-align: center;
  }

  .shadow {
    margin-left: 18cm;
  }

  .headComp {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 5%;
  }

  .Navbar {
    width: 100%;
    position: fixed;
    z-index: 4 !important;
    top: 0px;
  }

  .nav {
    width: 200px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto !important;
    grid-gap: 10px;
    margin-left: 2%;
  }

  .NavMenu {
    display: none;
  }

  .skill-info {
    font-size: 15px;
  }

  .active {
    position: fixed;
    width: 100%;
    height: 50%;
    z-index: 3 !important;
    left: 0px;
    transform: rotate(270deg);
    top: 20%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: white;
  }

  .grid-item {
    font-size: 20px;
  }

  .page {
    display: none;
  }
}

@media (max-width: 375px) {
  .cvBtn {
    font-size: 20px;
  }

  .skillsList {
    width: 258px;
  }

  .applied-skill {
    width: 30px;
    height: 25px;
    padding: 10px;
  }

  .skill-image {
    height: 40px;
  }

  .skill-info {
    font-size: 12px;
    padding-bottom: 2px;
  }

  .heading {
    font-size: 50px;
  }
}

@media (max-width: 320px) {
  .active {
    width: 90%;
    height: 55%;
  }
}

@media (max-width: 1024px) {
  .nav {
    font-size: 10px;
  }

  .nav::before {
    display: none;
  }
  .nav::after {
    display: none;
  }

  .headComp {
    justify-content: space-evenly;
    left: 0px;
  }

  .ArthurPic {
    width: 80px;
    height: 75px;
    border-radius: 60%;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    margin-top: 10px;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navImgBG {
    background-color: black;
    top: 0%;
    bottom: 0%;
    height: 100%;
    width: 18%;
  }

  .clicked::before {
    display: none;
  }
  .clicked::after {
    display: none;
  }

  .grid-projects {
    display: grid;
    grid-template-columns: auto !important;
    grid-gap: 20px;
    margin-left: 0%;
  }

  .grid-project {
    margin-top: 3%;
    margin-bottom: 10%;
    height: fit-content;
  }

  .project-img {
    max-width: 400px;
    min-height: 200px;
    max-height: 200px;
  }

  .AboutSection {
    display: flex;
    flex-direction: column;
  }

  .AboutImg {
    width: 100%;
  }

  .navImgBG {
    display: none;
  }

  .clicked {
    animation: scroll 70s linear infinite;
    background-size: 200%;
    perspective: 1000px;
    perspective-origin: 50% 50%;
    margin-right: 0rem;
    margin-top: 2rem !important;
    font-size: 2rem;
  }

  .Header {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    /* border-radius: 0.5cm; */
    padding: 10px;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
  }

  .Name {
    background: transparent;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 30px;
    margin-left: 2%;
  }
}

@media (max-width: 1024px) {
  .project-img {
    max-width: 500px;
    min-height: 200px;
    max-height: 200px;
  }

  .grid-projects {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
    margin-left: 0%;
  }

  .clicked {
    font-size: 1.2rem;
    margin-bottom: 2%;
  }

  .Name {
    height: 320px;
  }

  .headComp {
    margin-bottom: 1%;
  }
}

@media (max-width: 1440px) {
  .grid-projects {
    grid-template-columns: auto auto;
  }
}
